// src/reducers/userReducer.js
import Cookies from 'js-cookie';

const initialState = {
  isLoggedIn: Cookies.get('isLoggedIn') === 'true',
  user: Cookies.get('username') ? { username: Cookies.get('username') } : null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      Cookies.set('username', action.payload);
      Cookies.set('isLoggedIn', true);
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    case 'LOGOUT':
      Cookies.remove('username');
      Cookies.remove('isLoggedIn');
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
