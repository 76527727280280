import React from 'react'
import Hero from './Hero'
import ServiceSection from './ServiceSection'
import ContactForm from './ContactForm'
function Services() {
  return (
    <div>
        <Hero />
        <ServiceSection />
        <ContactForm />
    </div>
  )
}

export default Services