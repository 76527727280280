import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../Assets/about/hero.png";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="montserrat relative min-h-[70vh]  md:min-h-[90vh] w-full py-8">
      <div className="sectionlayout">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="w-full flex   min-h-[70vh]  md:min-h-[90vh]  items-center justify-start">
          <div
            className="relative w-full  drop-shadow-lg  z-10 text-center "
            data-aos="fade-up"
          >
            <div className="space-y-7 max-w-full lg:max-w-[50%] py-8 px-7 bg-white rounded-lg shadow-lg">
              <h3
                className=" drop-shadow-md text-black"
                style={{ fontSize: "clamp(18px,1.1vw,22px)" }}
              >
                About <span className="text-[#FFD700]"> Illuminex </span> <br />
              </h3>

              <h1
                className="font-semibold drop-shadow-lg text-left md:text-justify"
                style={{ fontSize: "clamp(22px,2.7vw,30px)" }}
              >
                 Crafting Luminous Experiences- Transforming Spaces, Illuminating Possibilities
              </h1>

              <p style={{ fontSize: "clamp(16px,0.8vw,17px)" }} className="text-left md:text-justify">
              More than a lighting company, we are visual storytellers who believe light is the most powerful medium of design. Illuminex Lighting emerges from a passion to redefine how spaces breathe and communicate through innovative illumination.
              From Dubai's skyscrapers to elegant homes, we craft lighting experiences that transcend ordinary design. Our team blends sustainable technologies and cutting-edge aesthetics to create environments that inspire and captivate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
