import React from 'react'
export default function ContactForm() {

  return (
    <div className="sectionlayout overflow-x-hidden">
      <div className="flex my-24 flex-col md:flex-row bg-white  space-y-6 md:space-y-0 md:space-x-10">
        <div className="md:w-1/2 space-y-4" data-aos="fade-right">
          <h2
            className={`text-[#FFD700] text-2xl font-bold source-serif`}
            style={{ fontSize: "clamp(34px,2vw,40px)" }}
          >
            Have a project?
          </h2>
          <h3
            className="text-2xl font-semibold source-serif"
            style={{ fontSize: "clamp(34px,2vw,40px)" }}
          >
            Let's discuss
          </h3>
          <p className="text-[#1e1e1e] md:w-[70%] ">
            Once you submit this form, expect to hear back from us within 24
            hours.
          </p>
          <div className="mt-4">
            <p className=" text-[#1e1e1e] text-[16px] py-2">REACH US AT</p>
            <p className="text-[#1e1e1e] font-semibold">info@illuminexlighting.ae</p>
            <p className="text-[#1e1e1e]  font-semibold py-2">
              +971 50 996 9258
            </p>
            <p className="text-[#1e1e1e]  font-semibold py-2">
              +971 50 636 7409
            </p>
          </div>
        </div>
        <form className="md:w-1/2 space-y-4" data-aos="fade-left">
          <input
            type="text"
            placeholder="Name"
            className={`w-full border border-gray-300 transition-all duration-300 ease-in-out p-3 rounded-md focus:border-none focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
          />
          <div className="flex sm:flex-row flex-col sm:space-x-4">
            <input
              type="text"
              placeholder="Phone"
              className={`w-full sm:w-1/2 border border-gray-300 transition-all duration-300 ease-in-out focus:border-none p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
            />
            <input
              type="text"
              placeholder="Company"
              className={`w-full mt-4 sm:mt-0 sm:w-1/2 border border-gray-300 transition-all duration-300 ease-in-out p-3 rounded-md focus:border-none focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
            />
          </div>
          <input
            type="text"
            placeholder="How did you hear about us?"
            className={`w-full border border-gray-300 transition-all duration-300 ease-in-out p-3 rounded-md focus:border-none focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
          />
          <textarea
            placeholder="How can we help you?"
            className={`w-full border border-gray-300 transition-all duration-300 ease-in-out p-3 rounded-md focus:border-none focus:outline-none focus:ring-2 focus:ring-[#FFD700] h-32 resize-none`}
          ></textarea>
          <button
            type="submit"
            className={`bg-[#FFD700] text-white font-semibold py-2.5 px-6 transition-all duration-300 ease-in-out border-2 border-[#FFD700]  hover:bg-transparent hover:text-[#FFD700] focus:outline-none focus:ring-2 focus:ring-[#FFD700]`}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
