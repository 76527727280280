import './Whatsapp.css'

function Whatsapp() {
  return (
    <>
      <a
        href="https://wa.me/971509969258"
        class="whatsapp-float"
        aria-label="Chat with us on WhatsApp"
      >
        <i class="fab fa-whatsapp whatsapp-icon"></i>
      </a>
    </>
  )
}

export default Whatsapp
