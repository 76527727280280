import React from 'react'
import linkedin from '../../Assets/socialmediaicons/linkedin.svg'
import facebook from '../../Assets/socialmediaicons/facebook.svg'
import instagram from '../../Assets/socialmediaicons/inst.svg'

function Social() {
    return (
        <div className='flex flex-row'>
            <a href='/' target='_blank' rel='noopener noreferrer' className='border rounded-[50%] flex p-3 '>
                <img src={linkedin} alt="LinkedIn" />
            </a>
            <a href='/' target='_blank' rel='noopener noreferrer' className='border rounded-[50%] flex p-3 '>
                <img src={facebook} alt="Facebook" />
            </a>
            <a href='/' target='_blank' rel='noopener noreferrer' className='border rounded-[50%] flex p-3'>
                <img src={instagram} alt="Instagram" />
            </a>
        </div>
    )
}

export default Social;
