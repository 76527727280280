import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../Assets/Services/Hero.png";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="montserrat relative min-h-[70vh] flex justify-center items-center py-8">
      <div className="sectionlayout">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div
          className="relative  drop-shadow-lg mx-auto z-10 text-center "
          data-aos="fade-up"
        >
          <div className="space-y-7 rounded-md mx-auto px-5 py-5 md:py-10 md:px-10 bg-black bg-opacity-40 max-w-[full] md:w-[600px] 2xl:w-[800px]  shadow-lg">
            <h1
              className="font-bold drop-shadow-lg text-white uppercase"
              style={{ fontSize: "clamp(22px,2.7vw,70px)" }}
            >
              <span className="text-[#FFD700]"> Ignite Your Space</span> <br />
              Illuminate Your Vision
            </h1>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Hero;
