import './index.css'
import React from 'react';
import { Routes, Route} from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Login from './Pages/Login/Login'
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
import Services from './Pages/Services/Services'



function App() {
  return (
    <>
    <div className='mainbodylayout'>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/services" element={<Services />} />
        
        <Route exact path="/login" element={<Login />} />
      </Routes>
      <Footer/>
    </div>
    </>
  );
}
export default App;

