import React, { useEffect } from 'react';
import logo from '../../Assets/logo/logo.jpg';
import { Link } from 'react-router-dom';
import Social from '../../Components/Social/Social';
import AOS from 'aos';
import 'aos/dist/aos.css';
import message from '../../Assets/socialmediaicons/email.svg';
import phone from '../../Assets/socialmediaicons/phone.svg';
import location from '../../Assets/socialmediaicons/location.svg';

function Footer() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration for the animation
      offset: 100,    // Offset value for the animation trigger
    });
  }, []);

  const handleLinkClick = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div className='w-full bg-[#ffffff] py-5'>
      <div className='w-full sectionlayout poppins'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-[#0A142F] py-8'>
          {/* Logo Section */}
          <div data-aos="fade-up">
            <Link to='/' onClick={handleLinkClick}>
              <img src={logo} alt="It-Solutions-Dynamic-Leo" className='max-h-[9vh] max-w-[70%] md:max-h-[60px] md:max-w-[220px]' />
            </Link>
            <div className='flex md:block my-6 items-center justify-start md:justify-center'>
              <Social />
            </div>
          </div>

          {/* Navigation Links */}
          <div data-aos="fade-up" data-aos-offset="50">
            <Link to="/" onClick={handleLinkClick}>
              <p className=' text-[15px] hover:text-[#CC890E] transition-all duration-300 ease-in-out hover:underline underline-[#CC890E]'>Home</p>
            </Link>
            <Link to="/services" onClick={handleLinkClick}>
              <p className='mt-5 text-[15px] hover:text-[#CC890E] transition-all duration-300 ease-in-out hover:underline underline-[#CC890E]'>Services</p>
            </Link>
            <Link to="/about" onClick={handleLinkClick}>
              <p className='mt-5 text-[15px] hover:text-[#CC890E] transition-all duration-300 ease-in-out hover:underline underline-[#CC890E]'>About Us</p>
            </Link>
            <Link to="/contact" onClick={handleLinkClick}>
              <p className='mt-5 pb-6 md:pb-0 text-[15px] hover:text-[#CC890E] transition-all duration-300 ease-in-out hover:underline underline-[#CC890E]'>Contact Us</p>
            </Link>
          </div>

          {/* Contact Information */}
          <div data-aos="fade-up" data-aos-offset="50">
            <p className=' text-[15px] flex items-center space-x-3'>
              <img src={message} alt="message" />
              <span>info@illuminexlighting.ae</span>
            </p>
            <p className='mt-5 text-[15px] flex space-x-3'>
              <img src={phone} alt="phone" />
              <span>
                +971 50 996 9258 <br /> +971 50 636 7409
              </span>
            </p>
            <p className='mt-5 text-[15px] flex items-start space-x-3'>
              <img src={location} alt="location" />
              <span>
              SHARJAH RESEARCH TECHNOLOGY AND INNOVATION PARK
              </span>
            </p>
          </div>

          {/* Schedule a Call Button */}
          <div data-aos="fade-up" data-aos-offset="50" className='flex items-center lg:justify-end'>
            <Link to="/contact" onClick={handleNav}>
              <button className="w-[202px] h-[50px] mt-6 md:mt-0 hover:text-white transition-all duration-300 ease-in-out border-[#CC890E] bg-transparent text-[#CC890E] border hover:bg-[#CC890E] rounded-[10px] font-semibold">
                Schedule a Call
              </button>
            </Link>
          </div>
        </div>

        {/* Footer Text */}
        <p className="text-[#6c6c6c] text-center mt-5 md:text-center">
          © 2024 Illuminex Lightning Solutions. All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
