import React from 'react'
import Hero from './Hero'
import Content from './Content'

function About() {
  return (
    <div>
    <Hero />
    <Content />
    </div>
  )
}

export default About