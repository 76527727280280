// src/actions/index.js
export const loginSuccess = (user) => {
    return {
      type: 'LOGIN_SUCCESS',
      payload: user,
    };
  };
  
  export const logout = () => {
    return {
      type: 'LOGOUT',
    };
  };
  
  export const signupSuccess = (user) => {
    return {
      type: 'SIGNUP_SUCCESS',
      payload: user,
    };
  };
  