import React from 'react'
import HeaderWithBackgroundImage from './Hero'
import ProcessSteps from './ProcessSteps'
import VissionMission from './VisionMission'

function Home() {
  return (
    <div>
      <HeaderWithBackgroundImage />
      <ProcessSteps />
      <VissionMission />
    </div>
  )
}

export default Home
