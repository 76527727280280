import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import videoBg from "../../Assets/home/hero.mp4";

const HeaderWithBackgroundImage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div className="relative min-h-[90vh] flex justify-center items-center py-8">
      <div className="sectionlayout">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoBg}
          autoPlay
          loop
          muted
          playsInline
        />
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <div
          className="relative md:w-[90%] lg:w-[75%] drop-shadow-lg mx-auto z-10 text-center text-[#F8F5F0]"
          data-aos="fade-up"
        >
          <h1
            className="font-bold drop-shadow-lg"
            style={{ fontSize: "clamp(32px,2.5vw,50px)" }}
          >
           Illuminating UAE with Premium Lighting Solutions
          </h1>
          <p
            className="mt-4 md:w-[84%] mx-auto font-medium"
            style={{ fontSize: "clamp(18px,1.2vw,24px)" }}
          >
            Discover a world of exceptional lighting products tailored to elevate your space. From stylish indoor fixtures to efficient outdoor lighting, we offer the finest selection for homes and businesses across the United Arab Emirates.
          </p>
          <Link to="/contact" onClick={handleNav}>
            <button
              onClick={handleNav}
              className="mt-6 px-6 py-3 font-semibold bg-[#FFD700] hover:bg-[#FFD900] text-black rounded-md shadow-lg transition duration-300"
            >
              Schedule a Call
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithBackgroundImage;
